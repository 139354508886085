<template>
  <div class="Map">
    <div id="map" style="min-height:800px;width: 100%"></div>
    <div class="alert-top">
      <div>
        <i
          class="fa fa-map-marker fa-lg"
          style="font-size: 18px;color: #097FFF"
        ></i
        ><span>我的机构</span>
      </div>
      <div>
        <i
          class="fa fa-map-marker fa-lg"
          style="font-size: 18px;color: #7672F9"
        ></i
        ><span>他们机构</span>
      </div>
    </div>
    <div class="left-container">
      <transition name="show">
        <div class="left-main" v-show="leftShow">
          <div class="content">
            <div class="row">
              <span>品牌名称</span>
              <div>
                <a-tree-select
                  style="width: 100%"
                  :treeData="treeData"
                  treeCheckable
                  searchPlaceholder="Please select"
                />
              </div>
            </div>

            <div class="row">
              <span>所属地区</span>
              <div>
                <a-tree-select
                  style="width: 100%"
                  :treeData="treeData"
                  treeCheckable
                  searchPlaceholder="Please select"
                />
              </div>
            </div>

            <div class="row">
              <span>开业时间</span>
              <div>
                <a-range-picker
                  style="width: 100%"
                  :placeholder="['开始时间', '结束时间']"
                />
              </div>
            </div>

            <div class="row">
              <span>机构状态</span>
              <div>
                <a-tree-select
                  style="width: 100%"
                  :treeData="StatusList"
                  treeCheckable
                  searchPlaceholder="Please select"
                />
              </div>
            </div>
            <div class="row" style="text-align: right;display: block">
              <a-button type="primary">开始查询</a-button>
            </div>

            <div class="row">
              <span class="list-title">机构列表(20)</span>
            </div>

            <div class="list-content">
              <ul>
                <li>
                  <div class="line ant-btn-primary"></div>
                  <div class="list-name">
                    <span>七田真</span>
                    <span>2017-11-21</span>
                  </div>
                  <div class="site">
                    地址：广东省深圳市南山区
                  </div>
                </li>
                <li>
                  <div class="line ant-btn-primary"></div>
                  <div class="list-name">
                    <span>七田真</span>
                    <span>2017-11-21</span>
                  </div>
                  <div class="site">
                    地址：广东省深圳市南山区
                  </div>
                </li>
                <li>
                  <div class="line ant-btn-primary"></div>
                  <div class="list-name">
                    <span>七田真</span>
                    <span>2017-11-21</span>
                  </div>
                  <div class="site">
                    地址：广东省深圳市南山区
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="add-btn ant-btn-primary" @click="onClose">
            <a-icon type="plus" />
          </div>
        </div>
      </transition>
      <div
        class="cut-btn"
        @click="_cutShow"
        @mouseover="_hintShow"
        @mouseout="_hintShow"
      >
        <a-icon
          :type="leftShow ? 'caret-left' : 'caret-right'"
          style="color:#8A8A8A"
        />
        <div class="hint-msg" v-show="hintMsg">
          <div class="san"></div>
          <span>{{ leftShow ? '收起侧边面板' : '展开侧边面板' }}</span>
        </div>
      </div>
    </div>
    <a-drawer
      title="基本信息"
      placement="right"
      :closable="false"
      @close="onClose"
      :visible="visible"
      width="400"
      style="position: relative"
    >
      <div class="drawer-content">
        <div class="row">
          <span>机构名称<sup>*</sup></span>
          <div>
            <a-input
              placeholder="请输入机构名称(30个字以内)"
              style="width: 100%"
            />
          </div>
        </div>

        <div class="row">
          <span>机构类型</span>
          <div>
            <a-radio-group defaultValue="a" buttonStyle="solid">
              <a-radio-button value="a">直营</a-radio-button>
              <a-radio-button value="b">加盟</a-radio-button>
              <a-radio-button value="c">其他</a-radio-button>
            </a-radio-group>
          </div>
        </div>

        <div class="row">
          <span>机构地址<sup>*</sup></span>
          <div>
            <a-cascader
              :options="siteData"
              placeholder="请选择"
              style="width: 100%"
            />
          </div>
        </div>

        <div class="row">
          <span>品牌类型<sup>*</sup></span>
          <div>
            <a-tree-select
              style="width: 100%"
              :treeData="brandTypeList"
              treeCheckable
              searchPlaceholder="Please select"
            />
          </div>
        </div>

        <div class="row">
          <span>开业日期</span>
          <div>
            <a-date-picker placeholder="请选择" style="width: 100%" />
          </div>
        </div>

        <div class="row">
          <span>公司官网</span>
          <div>
            <a-input placeholder="请填写" style="width: 100%" />
          </div>
        </div>

        <div class="row">
          <span>第几店</span>
          <div>
            <a-input placeholder="请填写" style="width: 100%">
              <span slot="suffix" class="suffix">个</span>
            </a-input>
          </div>
        </div>

        <div class="row">
          <span>面积</span>
          <div>
            <a-input placeholder="请填写" style="width: 100%">
              <span slot="suffix" class="suffix"
                >m<sup style="color:#666">2</sup></span
              >
            </a-input>
          </div>
        </div>

        <div class="row">
          <span>教室数量</span>
          <div>
            <a-input placeholder="请填写" style="width: 100%">
              <span slot="suffix" class="suffix">个</span>
            </a-input>
          </div>
        </div>

        <div class="row">
          <span>会员数</span>
          <div>
            <a-input placeholder="请填写" style="width: 100%">
              <span slot="suffix" class="suffix">个</span>
            </a-input>
          </div>
        </div>

        <div class="row">
          <span>月销售额</span>
          <div>
            <a-input placeholder="请填写" style="width: 100%">
              <span slot="suffix" class="suffix" style="width: 24px">万元</span>
            </a-input>
          </div>
        </div>

        <div class="row" style="align-items: end">
          <span>备注</span>
          <div>
            <a-textarea
              placeholder="请输入"
              style="width:100%;resize: none"
              :rows="4"
            />
          </div>
        </div>
      </div>
      <div class="drawer-bottom">
        <a-radio-button
          value="default"
          style="width: 120px;text-align: center;margin-right: 10px"
          >取消</a-radio-button
        >
        <a-button type="primary" style="width: 120px;text-align: center"
          >确认</a-button
        >
      </div>
    </a-drawer>
  </div>
</template>

<script>
import BMap from 'BMap'
export default {
  name: 'Map',
  data () {
    return {
      visible: false,
      leftShow: true,
      hintMsg: false,
      treeData: [
        {
          title: '全部',
          value: '0-0',
          key: '0-0',
          children: [
            {
              title: '七田真事业部',
              value: '0-0-0',
              key: '0-0-0'
            },
            {
              title: '小王子事业部',
              value: '0-0-1',
              key: '0-0-1'
            }
          ]
        }
      ],
      StatusList: [
        {
          title: '全部',
          value: '0-0',
          key: '0-0',
          children: [
            {
              title: '开业中',
              value: '0-0-0',
              key: '0-0-0'
            },
            {
              title: '筹备中',
              value: '0-0-1',
              key: '0-0-1'
            }
          ]
        }
      ],
      siteData: [
        {
          value: '广东省',
          label: '广东省',
          children: [
            {
              value: '深圳市',
              label: '深圳市',
              children: [
                {
                  value: '南山区',
                  label: '南山区'
                }
              ]
            }
          ]
        },
        {
          value: '广西壮族自治区',
          label: '广西壮族自治区',
          children: [
            {
              value: '钦州市',
              label: '钦州市',
              children: [
                {
                  value: '灵山县',
                  label: '灵山县'
                }
              ]
            }
          ]
        }
      ],
      brandTypeList: [
        {
          title: '全部',
          value: '0-0',
          key: '0-0',
          children: [
            {
              title: '七田真',
              value: '0-0-0',
              key: '0-0-0'
            },
            {
              title: '小王子',
              value: '0-0-1',
              key: '0-0-1'
            }
          ]
        }
      ]
    }
  },
  methods: {
    _hintShow () {
      this.hintMsg = !this.hintMsg
    },
    _cutShow () {
      this.leftShow = !this.leftShow
      this.hintMsg = false
    },
    onClose () {
      this.visible = !this.visible
    },
    createMap () {
      var map = new BMap.Map('map')
      // 初始化地图,设置中心点坐标和地图级别
      map.centerAndZoom(new BMap.Point(116.404, 39.915), 6)
      map.setCurrentCity('北京')
      let marker = new BMap.Marker(new BMap.Point(120.378386, 30.309756))
      map.addOverlay(marker)
      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true)
    }
  },
  mounted () {
    this.createMap()
  }
}
</script>

<style scoped lang="less">
.Map {
  position: relative;
  .left-container {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    height: 95%;
    background: #fff;
  }
  .left-main {
    max-width: 350px;
    height: 100%;
    padding: 20px 15px;
    border-radius: 2px;
  }
  .add-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  }
  .cut-btn {
    position: absolute;
    top: 10px;
    right: -20px;
    width: 20px;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border: 1px solid #e4e5e6;
    cursor: pointer;
    .hint-msg {
      position: absolute;
      top: 50%;
      right: -85px;
      transform: translateY(-50%);
      background: #000;
      opacity: 0.7;
      padding: 4px 8px;
      white-space: nowrap;
      font-size: 10px;
      line-height: 1.6;
      color: #fff;
      border-radius: 2px;
      .san {
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        border-right: 5px solid #000;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
  }
  .list-content {
    ul {
      li {
        position: relative;
        padding: 8px 16px;
        margin-bottom: 20px;
        .list-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          span:first-child {
            font-size: 16px;
            font-weight: 400;
          }
          span:last-child {
            font-size: 14px;
          }
        }
        box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
        .line {
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }
  .alert-top {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 8px 15px;
    padding-left: 10px;
    background: #fff;
    border-radius: 4px;
    div {
      margin-bottom: 5px;
    }
    div:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 8px;
    }
    span {
      color: #848484;
    }
  }
}
.drawer-content {
  padding-bottom: 40px;
}
.row {
  span {
    display: inline-block;
    width: 70px;
    white-space: nowrap;
    color: rgba(102, 102, 102, 1);
    sup {
      color: #ff4646;
    }
  }
  .suffix {
    display: inline-block;
    width: 12px;
    font-size: 12px;
  }
  > div {
    flex: 1;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .list-title {
    font-size: 18px;
    /*font-weight:bold;*/
    color: rgba(102, 102, 102, 1);
  }
}
.drawer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: left;
  z-index: 1000;
}
.show-enter-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-leave-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-enter,
.show-leave-to {
  width: 0;
  opacity: 0;
}
</style>
